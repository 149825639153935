import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";

// modules
import { FormattedMessage } from "react-intl";

// components
import { LangSelector } from "../langSelector/LangSelector";
import { SpeedRateSelector } from "./speedRateSelector/SpeedRateSelector";

// contexts
import { PlayerContext } from "../../contexts/PlayerContext";

// interfaces
import { PlayerData, PlayerProps, PlayerState } from '../../interfaces/player/player.interface';

// mui
import { styled, useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    Forward10,
    Headset,
    PauseRounded,
    PlayArrowRounded,
    PlayCircle,
    Replay10,
    SkipNext,
    SkipPrevious,
} from '@mui/icons-material';

import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

// actions
import {
    handleMute,
    handlePlayPause,
    handleSeekTo,
    handleSkipAd,
} from '../player/playerHandlers';

import { handleSkipTo } from "../playlist/playlistHandlers";

const TinyText = styled(Typography)({
    fontSize: "0.75rem",
    fontWeight: 700,
    letterSpacing: 0.0,
    width: 80
});

interface PlayButtonProps {
    playing: boolean,
    color: string,
    playButtonIconSize: string,
    playButtonIconCircle: boolean
}

const PlayButton = ({ playing, color, playButtonIconSize, playButtonIconCircle }: PlayButtonProps) => {
    return (
        <>
            {
                playing
                    ? (<PauseRounded sx={{ fontSize: playButtonIconSize }} htmlColor={color} />)
                    : (
                        playButtonIconCircle
                            ? <PlayCircle sx={{ fontSize: playButtonIconSize }} htmlColor={color} />
                            : <PlayArrowRounded sx={{ fontSize: playButtonIconSize }} htmlColor={color} />
                    )
            }
        </>
    )
}

export const PlayerButtons = () => {
    const theme = useTheme();
    const { palette } = theme;

    const playerContext = useContext(PlayerContext);
    const { style, data, props, type: playerType } = playerContext;

    const {
        playerRef,
        playerState,
        setPlayerState,
        position,
    } = props as PlayerProps;

    const { audioQueue } = data as PlayerData;
    const { visual, buttons } = style;
    const {
        buttonsSize,
        languageButtonDisplayRight,
        playButtonIconCircle,
        playButtonIconSize,
        primaryColor,
        showHeadsetIcon,
    } = visual;
    const {
        showPlayerForwardButton,
        showPlayerLanguageButton,
        showPlayerNextAudioButton,
        showPlayerPreviousAudioButton,
        showPlayerRewindButton,
        showPlayerSpeedButton,
        showMuteButton
    } = buttons;

    const color = palette.mode !== 'dark' ? primaryColor : palette.text.secondary
    const [adRemainingTime, setAdRemainingTime] = useState(0);

    const getSize = (buttonsSize: string): string => {
        switch (buttonsSize) {
            case 'small':
                return '1.5rem';
            case 'medium':
                return '2rem';
            case 'large':
                return '4rem';
            default:
                return '1rem';
        }
    }

    const fontSize = getSize(buttonsSize)
    const buttonsColor = palette.mode !== 'dark' ? primaryColor : palette.text.secondary
    const tooltipPlacement = playerType.mode === 'player' ? 'right' : 'top';

    useEffect(() => {
        setAdRemainingTime(playerState.adDuration)
    }, [playerState.adDuration])

    useEffect(() => {
        if (playerState.adDuration > 0 && adRemainingTime > 0) {
            setTimeout(() => setAdRemainingTime(adRemainingTime - 1), 1000);
            if (!playerState.adPlayed &&
                !playerState.adSkippable &&
                playerState.adSkipTimeOffset > - 1 &&
                adRemainingTime <= playerState.adDuration - playerState.adSkipTimeOffset) {
                setPlayerState(state => ({
                    ...state,
                    adSkippable: true
                }))
            }
        }
    }, [adRemainingTime, playerState.adPlayed, playerState.adSkippable, playerState.adSkipTimeOffset, playerState.adDuration, setPlayerState])


    function handlePlayAd(setPlayerState: Dispatch<SetStateAction<PlayerState>>): void {
        throw new Error("Function not implemented.");
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '55px',
                mb: (buttonsSize !== 'large' ? 0 : 2)
            }}
        >
            {
                showHeadsetIcon &&
                <Tooltip
                    placement={tooltipPlacement}
                    title={<FormattedMessage id='app.player-listen-label' />}
                >
                    <Headset htmlColor="inherit" sx={{ opacity: 0.3, mr: 1 }} />
                </Tooltip>
            }
            {
                showMuteButton && !playerState.adPlaying &&
                <Tooltip
                    placement="top"
                    title={<FormattedMessage id='app.player-volume-off-button' />}
                >
                    <span>
                        <IconButton
                            aria-label="volume off"
                            onClick={() => handleMute(playerState, setPlayerState)}
                            disabled={playerState.adPlaying}
                        >
                            {
                                !playerState.muted &&
                                <VolumeUpIcon htmlColor={buttonsColor} />
                            }
                            {
                                playerState.muted &&
                                <VolumeOffIcon htmlColor={buttonsColor} />
                            }
                        </IconButton>
                    </span>
                </Tooltip>
            }

            {
                showPlayerLanguageButton && audioQueue.length > 1 && !languageButtonDisplayRight &&
                <LangSelector />
            }
            {
                showPlayerPreviousAudioButton && !playerState.adPlaying &&
                <Tooltip
                    placement={tooltipPlacement}
                    title={<FormattedMessage id='app.player-previous-button' />}
                >
                    <IconButton
                        id="previousButton"
                        aria-label="previous audio"
                        onClick={() => handleSkipTo(-1, playerContext)}
                        disabled={playerState.adPlaying}
                    >
                        <SkipPrevious htmlColor={buttonsColor} sx={{ fontSize }} />
                    </IconButton>
                </Tooltip>
            }
            {
                showPlayerRewindButton && !playerState.adPlaying &&
                <Tooltip
                    placement={tooltipPlacement}
                    title={<FormattedMessage id='app.player-rewind10-button' />}
                >
                    <span>
                        <IconButton
                            aria-label="replay 10"
                            onClick={() => handleSeekTo(position, playerRef, -10)}
                            disabled={playerState.adPlaying}
                        >
                            <Replay10 htmlColor={buttonsColor} sx={{ fontSize }} />
                        </IconButton>
                    </span>
                </Tooltip>
            }
            <Tooltip
                placement={tooltipPlacement}
                title={
                    playerState.playing
                        ? <FormattedMessage id='app.player-pause-button' />
                        : <FormattedMessage id='app.player-play-button' />
                }
            >
                <span>
                    <IconButton
                        aria-label={playerState.playing ? 'pause' : 'play'}
                        data-test={`player-${playerState.playing ? 'pause' : 'play'}-button`}
                        id={playerState.playing || playerState.adPlaying ? 'pauseButton' : 'playButton'}
                        onClick={() =>
                            playerState.adLoaded && !playerState.adPlayed && !playerState.adPlaying ?
                                handlePlayAd(setPlayerState) : handlePlayPause(setPlayerState)}
                        disabled={playerState.adPlaying && !playerState.adPlayed ? true : false}
                    >
                        <PlayButton
                            playing={playerState.playing || playerState.adPlaying}
                            color={playerState.adPlaying && !playerState.adPlayed ?
                                'grey' : palette.mode === 'dark' ? palette.secondary.light : buttonsColor}
                            playButtonIconSize={playButtonIconSize}
                            playButtonIconCircle={playButtonIconCircle}
                        />
                    </IconButton>
                </span>
            </Tooltip>
            {
                showPlayerForwardButton && !playerState.adPlaying &&
                <Tooltip
                    placement={tooltipPlacement}
                    title={<FormattedMessage id='app.player-forward10-button' />}
                >
                    <span>
                        <IconButton
                            aria-label="forward ten"
                            onClick={() => handleSeekTo(position, playerRef, 10)}
                            disabled={playerState.adPlaying}
                        >
                            <Forward10 htmlColor={buttonsColor} sx={{ fontSize }} />
                        </IconButton>
                    </span>
                </Tooltip>
            }
            {
                showPlayerNextAudioButton && !playerState.adPlaying &&
                <Tooltip
                    placement={tooltipPlacement}
                    title={<FormattedMessage id='app.player-next-button' />}
                >
                    <span>
                        <IconButton
                            id="nextButton"
                            aria-label="next audio"
                            onClick={() => handleSkipTo(+1, playerContext)}
                            disabled={playerState.adPlaying}
                        >
                            <SkipNext htmlColor={buttonsColor} sx={{ fontSize }} />
                        </IconButton>
                    </span>
                </Tooltip>
            }
            {
                showPlayerSpeedButton &&
                <SpeedRateSelector />
            }
            {
                showPlayerLanguageButton && audioQueue.length > 1 && languageButtonDisplayRight &&
                <LangSelector />
            }
            {
                playerState.adPlaying &&
                <TinyText color={color}>
                    <FormattedMessage id="app.player-ad" /> {adRemainingTime}/{playerState.adDuration}s
                </TinyText>
            }
            {
                playerState.adPlaying && playerState.adSkippable &&
                <Button
                    id="skipAdButton"
                    aria-label="skip ad"
                    onClick={() => handleSkipAd(setPlayerState)}
                >
                    <TinyText color={color}>
                        <FormattedMessage id='app.player-skip-ad' />
                    </TinyText>
                    <SkipNext htmlColor={buttonsColor} sx={{ fontSize }} />
                </Button>
            }
        </Box>
    )
}
