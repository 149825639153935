import { useContext } from 'react';

// modules
import { FormattedMessage } from "react-intl";

// contexts
import { PlayerContext } from '../../../contexts/PlayerContext';

// mui
import { useTheme } from '@mui/material/styles';
import {
    Button,
    Tooltip,
    Typography
} from '@mui/material';

// interfaces
import { PlayerProps } from '../../../interfaces/player/player.interface';
import { PlaybackRate } from '../../../interfaces/player/playbackRate.interface';


interface Props {
    playbackRates: PlaybackRate[]
}

export const SpeedRateSelectorButton = ({ playbackRates }: Props) => {
    const theme = useTheme();
    const { palette } = theme;

    const playerContext = useContext(PlayerContext);
    const { style, props, type: playerType } = playerContext;
    const { visual } = style;
    const { primaryColor } = visual;
    const color = palette.mode !== 'dark' ? primaryColor : palette.text.secondary

    const {
        playerState,
        setPlayerState,
    } = props as PlayerProps;

    const handleSwitchRate = () => {
        const statePlaybackFilter = playbackRates.filter(rate => rate.playbackRate === playerState.playbackRate)[0]

        if (statePlaybackFilter) {
            let newId = statePlaybackFilter.id + 1;
            if (newId === playbackRates.length) newId = 0
            const rateFilter = playbackRates.filter(rate => rate.id === newId)[0];

            if (rateFilter) {
                const {
                    playbackRate,
                    playbackRateLabel
                } = rateFilter

                setPlayerState(state => ({
                    ...state,
                    playbackRate,
                    playbackRateLabel
                }))
            }
        }
    }

    const tooltipPlacement = playerType.mode === 'player' ? 'right' : 'top';

    return (
        <Tooltip
            placement={tooltipPlacement}
            title={<FormattedMessage id='app.player-playbackRate-button' />}
        >
            <span>
                <Button
                    id="playback-button"
                    onClick={handleSwitchRate}
                    sx={{ color }}
                    disabled={playerState.adPlaying ? true : false}
                >
                    <Typography color={playerState.adPlaying ? 'grey' : color}>
                        {playerState.playbackRateLabel}
                    </Typography>
                </Button>
            </span>
        </Tooltip>
    )
}
